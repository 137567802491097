// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/don/axlry-web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("/Users/don/axlry-web/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/Users/don/axlry-web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-contact-js": () => import("/Users/don/axlry-web/src/pages/support/contact.js" /* webpackChunkName: "component---src-pages-support-contact-js" */),
  "component---src-pages-support-privacy-js": () => import("/Users/don/axlry-web/src/pages/support/privacy.js" /* webpackChunkName: "component---src-pages-support-privacy-js" */),
  "component---src-pages-support-terms-js": () => import("/Users/don/axlry-web/src/pages/support/terms.js" /* webpackChunkName: "component---src-pages-support-terms-js" */),
  "component---src-pages-usermgmt-email-recovery-email-recovery-js": () => import("/Users/don/axlry-web/src/pages/usermgmt/EmailRecovery/EmailRecovery.js" /* webpackChunkName: "component---src-pages-usermgmt-email-recovery-email-recovery-js" */),
  "component---src-pages-usermgmt-email-recovery-index-js": () => import("/Users/don/axlry-web/src/pages/usermgmt/EmailRecovery/index.js" /* webpackChunkName: "component---src-pages-usermgmt-email-recovery-index-js" */),
  "component---src-pages-usermgmt-email-verification-email-verification-js": () => import("/Users/don/axlry-web/src/pages/usermgmt/EmailVerification/EmailVerification.js" /* webpackChunkName: "component---src-pages-usermgmt-email-verification-email-verification-js" */),
  "component---src-pages-usermgmt-email-verification-index-js": () => import("/Users/don/axlry-web/src/pages/usermgmt/EmailVerification/index.js" /* webpackChunkName: "component---src-pages-usermgmt-email-verification-index-js" */),
  "component---src-pages-usermgmt-index-js": () => import("/Users/don/axlry-web/src/pages/usermgmt/index.js" /* webpackChunkName: "component---src-pages-usermgmt-index-js" */),
  "component---src-pages-usermgmt-password-reset-index-js": () => import("/Users/don/axlry-web/src/pages/usermgmt/PasswordReset/index.js" /* webpackChunkName: "component---src-pages-usermgmt-password-reset-index-js" */),
  "component---src-pages-usermgmt-password-reset-password-reset-js": () => import("/Users/don/axlry-web/src/pages/usermgmt/PasswordReset/PasswordReset.js" /* webpackChunkName: "component---src-pages-usermgmt-password-reset-password-reset-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/don/axlry-web/.cache/data.json")

