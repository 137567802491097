module.exports = [{
      plugin: require('/Users/don/axlry-web/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/don/axlry-web/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/config/styles/Typography.js"},
    },{
      plugin: require('/Users/don/axlry-web/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-131420419-1","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]},
    }]
